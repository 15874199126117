'use client';

import { PropsWithChildren } from 'react';
import { Box } from '@mui/material';
export const StartChatWrapper = ({
  children
}: PropsWithChildren) => {
  const handleStartChatClick = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    e.preventDefault();
    window?.salesPilotPostMessages?.openChatPage();
  };
  return <Box onClick={handleStartChatClick} color="tertiary.contrastText" sx={{
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    p: 'inherit',
    display: 'inherit',
    flexDirection: 'inherit',
    alignItems: 'inherit',
    justifyContent: 'inherit',
    gap: 'inherit'
  }} data-sentry-element="Box" data-sentry-component="StartChatWrapper" data-sentry-source-file="StartChatWrapper.tsx">
      {children}
    </Box>;
};