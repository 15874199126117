'use client';

import { Box } from '@mui/material';
import { useIsMobile } from '@qb/frontend/hooks/mediaQueries';
import { PartDisplayParams } from '@/shared/classes/SPSPageComponentParams/PartDisplayParams';
import { PartDisplayDataResponse } from '@/shared/types/controllers/sps/SPSPartControllerTypes';
import { TrustBoxBottomLinks } from './TrustBoxBottomLinks';
import { TrustBoxPriceDetails } from './TrustBoxPriceDetails';
import { TrustBoxTopLinks } from './TrustBoxTopLinks';
const NAVBAR_HEIGHT = 0;
type TrustBoxProps = Pick<PartDisplayDataResponse, 'part' | 'publicQtyAndPrice'> & {
  partDisplayParams: PartDisplayParams;
};
export const TrustBox = ({
  part,
  publicQtyAndPrice,
  partDisplayParams
}: TrustBoxProps) => {
  const isMobile = useIsMobile();
  if (isMobile) {
    return <>
        <TrustBoxPriceDetails part={part} publicQtyAndPrice={publicQtyAndPrice} />
        <TrustBoxTopLinks partDisplayParams={partDisplayParams} />
      </>;
  }
  return <Box sx={{
    borderStyle: 'solid',
    borderWidth: 8,
    borderColor: 'common.backgroundColor',
    position: 'sticky',
    top: NAVBAR_HEIGHT
  }} data-sentry-element="Box" data-sentry-component="TrustBox" data-sentry-source-file="TrustBox.tsx">
      <TrustBoxPriceDetails part={part} publicQtyAndPrice={publicQtyAndPrice} data-sentry-element="TrustBoxPriceDetails" data-sentry-source-file="TrustBox.tsx" />
      <TrustBoxTopLinks partDisplayParams={partDisplayParams} data-sentry-element="TrustBoxTopLinks" data-sentry-source-file="TrustBox.tsx" />
      <TrustBoxBottomLinks data-sentry-element="TrustBoxBottomLinks" data-sentry-source-file="TrustBox.tsx" />
    </Box>;
};