'use client';

import { Button, BoxProps } from '@mui/material';
type SearchAndChatChatWithUsProps = {
  width: BoxProps['width'];
};
export const SearchAndChatChatWithUs = ({
  width: maxWidth
}: SearchAndChatChatWithUsProps) => {
  return <Button variant="contained" color="primary" fullWidth size="large" sx={{
    maxWidth
  }} onClick={e => {
    e.preventDefault();
    window?.salesPilotPostMessages?.openChatPage();
  }} data-sentry-element="Button" data-sentry-component="SearchAndChatChatWithUs" data-sentry-source-file="SearchAndChatChatWithUs.tsx">
      Chat with us
    </Button>;
};