import { randomDarkColorsList } from '@qb/base-theme/randomColorsList';

/**
 * @see https://en.wikipedia.org/wiki/Linear_congruential_generator
 * @param value
 * @param length
 */
export const stringAsciiPRNG = (value: string, length: number) => {
  const charCodes = value.split('').map((letter) => letter.charCodeAt(0));
  const len = charCodes.length;

  const a = (len % (length - 1)) + 1;
  const c = charCodes.reduce((current, next) => current + next) % length;

  let random = charCodes[0] % length;
  for (let i = 0; i < len; i++) random = (a * random + c) % length;

  return random;
};

/**
 * A certain value will always get the same color assigned given a fixed set of colors
 * @param value
 * @param colors
 */
export const stringToColor = (
  value: string,
  colors: string[] = randomDarkColorsList,
) => {
  const colorIndex = stringAsciiPRNG(value, colors.length);
  return colors[colorIndex];
};
