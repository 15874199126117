'use client';

import Image from 'next/image';
import { Button, Container, Typography, Stack } from '@mui/material';
import iconRobot from '@qb/frontend/components/assets/iconRobot.svg';
export const BenefitsBanner = () => <Container data-sentry-element="Container" data-sentry-component="BenefitsBanner" data-sentry-source-file="BenefitsBanner.tsx">
    <Stack direction="column" alignItems="center" justifyContent="center" sx={{
    pt: 7,
    pb: 7
  }} data-sentry-element="Stack" data-sentry-source-file="BenefitsBanner.tsx">
      <Image alt="" src={iconRobot} width={112} height={97} data-sentry-element="Image" data-sentry-source-file="BenefitsBanner.tsx" />
      <Typography variant="h2" component="p" align="center" mb={2} mt={1} data-sentry-element="Typography" data-sentry-source-file="BenefitsBanner.tsx">
        What can I help you find today?
      </Typography>
      <Button onClick={() => window?.salesPilotPostMessages?.openChatPage()} variant="contained" color="secondary" size="large" fullWidth sx={{
      maxWidth: '674px'
    }} data-sentry-element="Button" data-sentry-source-file="BenefitsBanner.tsx">
        Chat with us
      </Button>
    </Stack>
  </Container>;