'use client';

import { useState, useContext } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Typography, Box, Collapse, IconButton } from '@mui/material';
import { Rotate } from '@qb/frontend/components/Rotate';
import { convertStringToParagraphs } from '@qb/frontend/utils/convertStringToParagraphs';
import { TenantStoreContext } from '@/global/tenantStore/TenantStoreContext';
import { PartDisplayDataResponse } from '@/shared/types/controllers/sps/SPSPartControllerTypes';
const COLLAPSE_MIN_HEIGHT = 138;
type PartDescriptionCollapseProps = Pick<PartDisplayDataResponse['part'], 'description' | 'manufacturer' | 'partNumber'> & Pick<PartDisplayDataResponse, 'partCategoriesToRoot'>;
export const PartDescriptionCollapse = ({
  partNumber,
  description,
  manufacturer,
  partCategoriesToRoot
}: PartDescriptionCollapseProps) => {
  const [expanded, setExpanded] = useState(false);
  const partCategory = partCategoriesToRoot.at(-1);
  const appInfo = useContext(TenantStoreContext);
  return <Box sx={{
    mt: 'auto',
    position: 'relative',
    minHeight: COLLAPSE_MIN_HEIGHT + 16,
    bgcolor: 'common.backgroundColor',
    zIndex: 1
  }} data-sentry-element="Box" data-sentry-component="PartDescriptionCollapse" data-sentry-source-file="PartDescriptionCollapse.tsx">
      <IconButton onClick={() => setExpanded(!expanded)} aria-expanded={expanded} aria-label="show more" sx={{
      position: 'absolute',
      right: 8,
      top: 49,
      zIndex: 2
    }} data-sentry-element="IconButton" data-sentry-source-file="PartDescriptionCollapse.tsx">
        <Rotate isRotated={expanded} data-sentry-element="Rotate" data-sentry-source-file="PartDescriptionCollapse.tsx">
          <ExpandMoreIcon data-sentry-element="ExpandMoreIcon" data-sentry-source-file="PartDescriptionCollapse.tsx" />
        </Rotate>
      </IconButton>

      <Collapse in={expanded} collapsedSize={COLLAPSE_MIN_HEIGHT} sx={{
      position: ['static', 'static', 'absolute'],
      p: 2,
      pr: 6,
      bgcolor: 'common.backgroundColor'
    }} data-sentry-element="Collapse" data-sentry-source-file="PartDescriptionCollapse.tsx">
        <Box sx={{
        position: 'absolute',
        height: 16,
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 1,
        // Smoothen out the cut out text at the bottom of the collapse.
        background: theme => `linear-gradient(
                0deg,
                ${theme.palette.common.backgroundColor} 0%,
                ${theme.palette.common.backgroundColor} 20%,
                transparent 100%
              )
             `
      }} data-sentry-element="Box" data-sentry-source-file="PartDescriptionCollapse.tsx" />
        <Typography variant="paragraph" align="justify" mb={0.5} sx={{
        whiteSpace: 'pre-line'
      }} data-sentry-element="Typography" data-sentry-source-file="PartDescriptionCollapse.tsx">
          {convertStringToParagraphs(description)}
        </Typography>
        {manufacturer.description && <Typography variant="paragraph" align="justify" mb={0.5}>
            <Typography variant="h6" component="span" lineHeight={1.5}>
              About the manufacturer:{' '}
            </Typography>
            {manufacturer.name} is one of the manufacturers that offers{' '}
            {partCategory?.name} that you can order at{' '}
            {appInfo?.app.company.name} site. {manufacturer.description}
          </Typography>}
        <Typography variant="paragraph" align="justify" data-sentry-element="Typography" data-sentry-source-file="PartDescriptionCollapse.tsx">
          Check out the {partNumber} {manufacturer.name} price in the box on the
          right side or send a quick quote.
        </Typography>
      </Collapse>
    </Box>;
};