export abstract class ParamsParent {
  constructor(
    private componentCode: number,
    private fieldsDefinition: FieldDefinition[],
  ) {}

  public getFields() {
    return this.fieldsDefinition.map(
      ({
        name,
        type,
        subtype,
        options,
        maxLength,
        minWidth,
        maxWidth,
        minHeight,
        maxHeight,
        insertDividerAfter,
      }) => ({
        fieldName: name,
        fieldType: type,
        fieldSubtype: subtype,
        fieldOptions: options,
        maxLength: maxLength,
        minWidth: minWidth,
        maxWidth: maxWidth,
        minHeight: minHeight,
        maxHeight: maxHeight,
        insertDividerAfter,
      }),
    );
  }

  get getComponentCode(): number {
    return this.componentCode;
  }
}

export type FieldDefinition = {
  name: string;
  type: FieldType;
  subtype?: FieldSubtype;
  options?: string[];
  maxLength?: number;
  minWidth?: number;
  maxWidth?: number;
  minHeight?: number;
  maxHeight?: number;
  // Used for UI of the internalAdmin only, to help separate form sections visually.
  insertDividerAfter?: boolean;
};
export type FieldType = 'shortText' | 'longText' | 'id' | 'url' | 'select';
export type FieldSubtype = 'image';
