import { CustomBreadcrumb } from '@/components/Common/CustomBreadcrumb/CustomBreadcrumb';
import { PartDisplayDataResponse } from '@/shared/types/controllers/sps/SPSPartControllerTypes';
import { generateSPSPartCategorySearchURL, generatePartURL } from '@/shared/utils/URLs';
type PartPageBreadcrumbsProps = {
  partID: PartDisplayDataResponse['part']['id'];
  partNumber: PartDisplayDataResponse['part']['partNumber'];
  partManufacturerName: PartDisplayDataResponse['part']['manufacturer']['name'];
  partCategoriesToRoot: PartDisplayDataResponse['partCategoriesToRoot'];
};
export const PartBreadcrumbs = ({
  partID,
  partNumber,
  partManufacturerName,
  partCategoriesToRoot
}: PartPageBreadcrumbsProps) => {
  const categoryLinks = partCategoriesToRoot.map(category => ({
    href: generateSPSPartCategorySearchURL(category.name, category.id),
    name: category.name
  }));
  const partLink = {
    href: generatePartURL(partManufacturerName, partNumber, partID),
    name: partNumber
  };
  return <CustomBreadcrumb ariaLabel="category and part breadcrumbs" links={[...categoryLinks, partLink]} data-sentry-element="CustomBreadcrumb" data-sentry-component="PartBreadcrumbs" data-sentry-source-file="PartBreadcrumbs.tsx" />;
};