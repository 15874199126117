'use client';

import { useState, PropsWithChildren } from 'react';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Button, Box, Collapse } from '@mui/material';
export const MoreManufacturersCollapse = ({
  children
}: PropsWithChildren) => {
  const [isVisible, setIsVisible] = useState(false);
  return <>
      <Collapse in={isVisible} data-sentry-element="Collapse" data-sentry-source-file="MoreManufacturersCollapse.tsx">{children}</Collapse>
      <Box sx={{
      display: 'flex',
      justifyContent: 'center',
      mt: {
        xs: 2,
        md: 4,
        lg: 6
      }
    }} data-sentry-element="Box" data-sentry-source-file="MoreManufacturersCollapse.tsx">
        <Button onClick={() => setIsVisible(prev => !prev)} variant="contained" color="primary" sx={{
        minWidth: {
          xs: 240,
          sm: 400
        }
      }} endIcon={isVisible ? <ExpandLessIcon /> : <ExpandMoreIcon />} data-sentry-element="Button" data-sentry-source-file="MoreManufacturersCollapse.tsx">
          {`See ${isVisible ? 'less' : 'more'}`}
        </Button>
      </Box>
    </>;
};