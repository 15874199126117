'use client';

import Image from 'next/image';
import { useIsMobile } from '@qb/frontend/hooks/mediaQueries';
type Props = {
  src: string;
  alt: string;
};
export const HeroBannerImage = ({
  src,
  alt
}: Props) => {
  const isMobile = useIsMobile();
  return <Image src={src} fill quality={100} priority style={{
    objectPosition: isMobile ? 'center center' : 'left center',
    objectFit: 'cover',
    zIndex: -1
  }} alt={alt} data-sentry-element="Image" data-sentry-component="HeroBannerImage" data-sentry-source-file="HeroBannerImage.tsx" />;
};