export const randomLightColorsList = [
  '#d73d32',
  '#67ae3f',
  '#ff4080',
  '#81efea',
  '#db51a8',
  '#e23db6',
  '#ffccf6',
  '#d6dcf0',
  '#b770f9',
  '#53c1e2',
  '#218de0',
  '#5a6cce',
  '#e5da3b',
  '#93e884',
  '#f7f25d',
  '#dd9b66',
  '#bfdcfc',
  '#899add',
];
export const randomDarkColorsList = [
  '#7e3794',
  '#4285f4',
  '#d61a7f',
  '#ef5807',
  '#a528d6',
  '#4a3799',
  '#1c4f82',
  '#289909',
];
export const randomColorsList = [
  ...randomDarkColorsList,
  ...randomLightColorsList,
];
