import { useEffect, useRef, useCallback } from 'react';
import { useParams } from 'next/navigation';
import { getHostFromWindow } from '@qb/frontend/utils/hosts/getHostFromWindow';
import { spsQueries } from '@qb/httpRequest/spsQueries';
import { PageParams } from '@/global/types/types';
import { useRequestPartPriceFromSPW } from '@/hooks/useRequestPartPriceFromSPW';
export const useRecordPartView = (partID: number) => {
  const params = useParams<PageParams>();
  const hasRunOnce = useRef(false);
  const {
    authUser
  } = useRequestPartPriceFromSPW();
  const callbackToRunOnce = useCallback(() => {
    if (params?.tenantAlias) {
      spsQueries.part.recordPartView(getHostFromWindow(), params.tenantAlias, partID).mutationFn();
    }
  }, [params, partID]);
  const isAuthUserReceived = typeof authUser !== 'undefined';
  const shouldRun = isAuthUserReceived && !!partID;
  useEffect(() => {
    if (shouldRun && !hasRunOnce.current) {
      callbackToRunOnce();
      hasRunOnce.current = true;
    }
  }, [shouldRun, callbackToRunOnce]);
};