import { Link as MuiLink, Typography } from '@mui/material';
type BreadcrumbLinkProps = {
  href?: string;
  children: React.ReactNode;
};
export const BreadcrumbLink = ({
  href,
  children
}: BreadcrumbLinkProps) => {
  if (href) {
    return <MuiLink href={href} sx={{
      textDecoration: 'none',
      '&:hover, &:focus': {
        color: 'common.white',
        textDecoration: 'underline'
      }
    }}>
        <Typography variant="h6" component="span" sx={{
        color: 'common.white'
      }}>
          {children}
        </Typography>
      </MuiLink>;
  }
  return <Typography variant="h6" component="span" sx={{
    color: 'common.white'
  }} data-sentry-element="Typography" data-sentry-component="BreadcrumbLink" data-sentry-source-file="BreadcrumbLink.tsx">
      {children}
    </Typography>;
};