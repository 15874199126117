'use client';

import { Box } from '@mui/material';
export const VerticalDivider = () => <Box sx={theme => ({
  width: {
    xs: '100%',
    md: '1px'
  },
  height: {
    xs: '1px',
    md: '100%'
  },
  bgcolor: 'tertiary.8p',
  boxShadow: theme.shadows[6]
})} data-sentry-element="Box" data-sentry-component="VerticalDivider" data-sentry-source-file="VerticalDivider.tsx" />;