'use client';

import HomeIcon from '@mui/icons-material/Home';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Breadcrumbs, Link, Typography } from '@mui/material';
import { useIsMobile } from '@qb/frontend/hooks/mediaQueries';
type Props = {
  links: {
    href: string | null;
    name: string;
  }[];
  ariaLabel: string;
};
export const CustomBreadcrumb = ({
  links,
  ariaLabel
}: Props) => {
  const isMobile = useIsMobile();
  return <Breadcrumbs maxItems={isMobile ? 4 : undefined} itemsBeforeCollapse={2} itemsAfterCollapse={2} aria-label={ariaLabel} sx={{
    color: 'tertiary.light',
    fontSize: 14,
    '& li:nth-last-of-type(-n+2)': {
      display: {
        xs: 'none',
        md: 'inherit'
      }
    }
  }} separator={<NavigateNextIcon color="inherit" fontSize="small" />} data-sentry-element="Breadcrumbs" data-sentry-component="CustomBreadcrumb" data-sentry-source-file="CustomBreadcrumb.tsx">
      <Link href="/" underline="none" sx={{
      display: 'flex',
      alignItems: 'center',
      gap: 1,
      color: 'inherit',
      '&:hover, &:hover svg': {
        color: 'primary.main'
      }
    }} data-sentry-element="Link" data-sentry-source-file="CustomBreadcrumb.tsx">
        <HomeIcon sx={{
        mt: -0.1,
        fontSize: 'large',
        color: 'tertiary.30p'
      }} data-sentry-element="HomeIcon" data-sentry-source-file="CustomBreadcrumb.tsx" />
        Homepage
      </Link>
      {links.map(({
      name,
      href
    }) => {
      if (href) {
        return <Link key={name} href={href} underline="none" sx={{
          color: 'inherit',
          '&:hover': {
            color: 'primary.main'
          }
        }}>
              {name}
            </Link>;
      }
      return <Typography key={name} variant="bodyMedium">
            Product Category
          </Typography>;
    })}
    </Breadcrumbs>;
};