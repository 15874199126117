'use client';

import React from 'react';
import { Typography } from '@mui/material';
import { useIsMobile } from '@qb/frontend/hooks/mediaQueries';
import { PartDisplayParams } from '@/shared/classes/SPSPageComponentParams/PartDisplayParams';
import { PartDisplayDataResponse } from '@/shared/types/controllers/sps/SPSPartControllerTypes';
import { PartDescriptionCollapse } from './PartDescriptionCollapse';
import { PartOnHandQuantityStatus } from './PartOnHandQuantityStatus';
import { TrustBox } from './TrustBox/TrustBox';
type PartBasicInfoProps = Pick<PartDisplayDataResponse, 'part' | 'publicQtyAndPrice' | 'partCategoriesToRoot'> & {
  partDisplayParams: PartDisplayParams;
};
export const PartBasicInfo = ({
  part,
  publicQtyAndPrice,
  partCategoriesToRoot,
  partDisplayParams
}: PartBasicInfoProps) => {
  const {
    alternatePartNumber,
    partNumber,
    active,
    manufacturer,
    description
  } = part;
  const isMobile = useIsMobile();
  return <>
      <Typography variant="h6" component="h2" width="100%" mb={1} data-sentry-element="Typography" data-sentry-source-file="PartBasicInfo.tsx">
        Part#:
        <Typography ml={1} variant="paragraph" component="span" data-sentry-element="Typography" data-sentry-source-file="PartBasicInfo.tsx">
          {partNumber}
        </Typography>
      </Typography>
      <Typography variant="h6" component="h2" width="100%" mb={1} data-sentry-element="Typography" data-sentry-source-file="PartBasicInfo.tsx">
        Manufacturer:
        <Typography ml={1} variant="paragraph" component="span" data-sentry-element="Typography" data-sentry-source-file="PartBasicInfo.tsx">
          {manufacturer.name}
        </Typography>
      </Typography>
      {!!alternatePartNumber && <Typography variant="h6" component="h2" width="100%" mb={1}>
          Alt. Part#:
          <Typography ml={1} variant="paragraph" color="primary" component="span">
            {alternatePartNumber}
          </Typography>
        </Typography>}
      <Typography variant="h6" component="p" width="100%" mb={1} data-sentry-element="Typography" data-sentry-source-file="PartBasicInfo.tsx">
        Product Lifecycle:
        <Typography ml={1} variant="h6" component="span" color={active ? 'success.main' : 'error.main'} data-sentry-element="Typography" data-sentry-source-file="PartBasicInfo.tsx">
          {active ? 'Active' : 'Discontinued'}
        </Typography>
      </Typography>
      <PartOnHandQuantityStatus publicQtyAndPrice={publicQtyAndPrice} data-sentry-element="PartOnHandQuantityStatus" data-sentry-source-file="PartBasicInfo.tsx" />
      {isMobile && <TrustBox part={part} publicQtyAndPrice={publicQtyAndPrice} partDisplayParams={partDisplayParams} />}
      <PartDescriptionCollapse partNumber={partNumber} manufacturer={manufacturer} partCategoriesToRoot={partCategoriesToRoot} description={description} data-sentry-element="PartDescriptionCollapse" data-sentry-source-file="PartBasicInfo.tsx" />
    </>;
};