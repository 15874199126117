import React from 'react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Box, Typography, Stack } from '@mui/material';
export const PartCondition = () => {
  return <>
      <Box sx={{
      my: 2,
      px: [0, 0, 2]
    }} data-sentry-element="Box" data-sentry-source-file="PartCondition.tsx">
        <Typography variant="h5" component="h3" mb={2} data-sentry-element="Typography" data-sentry-source-file="PartCondition.tsx">
          Product condition
        </Typography>
        <Stack direction="row" mb={1} data-sentry-element="Stack" data-sentry-source-file="PartCondition.tsx">
          <Typography variant="bodyMedium" sx={{
          py: 1.5,
          px: 2,
          color: 'text.primary',
          bgcolor: 'success.12p',
          display: 'flex',
          alignItems: 'center'
        }} data-sentry-element="Typography" data-sentry-source-file="PartCondition.tsx">
            <CheckCircleIcon color="success" sx={{
            mr: 1.75
          }} data-sentry-element="CheckCircleIcon" data-sentry-source-file="PartCondition.tsx" />
            New Factory Sealed
          </Typography>
        </Stack>
      </Box>
      <Typography variant="paragraph" pr={6} pl="14px" mb={2} sx={{
      borderLeft: '2px solid',
      borderLeftColor: 'secondary.main'
    }} data-sentry-element="Typography" data-sentry-source-file="PartCondition.tsx">
        <Typography variant="bodyLargePrimary" sx={{
        color: 'text.primary'
      }} component="span" data-sentry-element="Typography" data-sentry-source-file="PartCondition.tsx">
          New Factory Sealed
        </Typography>{' '}
        - New condition, never used, original OEM part with original packaging
        and factory seal.
      </Typography>
    </>;
};