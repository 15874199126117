import { PropsWithChildren } from 'react';
import { Box, BoxProps } from '@mui/material';
type Props = PropsWithChildren<{
  isRotated: boolean;
  startDeg?: number;
  endDeg?: number;
  duration?: number | string;
  sx?: BoxProps['sx'];
}>;
export const Rotate = ({
  children,
  isRotated,
  startDeg = 0,
  endDeg = 180,
  duration,
  sx = {}
}: Props) => <Box sx={{
  transform: !isRotated ? `rotate(${startDeg}deg)` : `rotate(${endDeg}deg)`,
  transition: ({
    transitions
  }) => transitions.create('transform', {
    duration
  }),
  display: 'flex',
  ...sx
}} data-sentry-element="Box" data-sentry-component="Rotate" data-sentry-source-file="Rotate.tsx">
    {children}
  </Box>;