'use client';

import React, { ForwardedRef, forwardRef, useEffect, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { ButtonGroup, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import { SxProps } from '@mui/system';
const baseButtonSx = {
  backgroundColor: 'primary.8p',
  borderRadius: 0,
  p: 0,
  width: 23,
  height: 32,
  '&:hover': {
    backgroundColor: 'primary.12p'
  }
};
const StyledInput = styled('input')(({
  theme
}) => ({
  minWidth: '3.5ch',
  textAlign: 'center',
  border: 'none',
  fontSize: 16,
  color: theme.palette.text.primary,
  outline: 0,
  /* Chrome, Safari, Edge, Opera */
  '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
    WebkitAppearance: 'none',
    margin: '0'
  },
  /* Firefox */
  "&[type='number']": {
    MozAppearance: 'textfield'
  }
}));
type Props = {
  onValueChange: (value: number) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  initialValue: number;
  minValue?: number;
  maxValue?: number;
  isInputDisabled?: boolean;
  buttonSx?: SxProps;
};
const NumberInputInternal = ({
  onValueChange,
  onBlur,
  initialValue,
  minValue = 1,
  maxValue = 9999,
  isInputDisabled = false,
  buttonSx = {}
}: Props, ref: ForwardedRef<HTMLInputElement>) => {
  const [internalValue, setInternalValue] = useState<number | ''>(initialValue);
  const cleanInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const cleanedStr = e.target.value.replace(/[^0-9\.]/g, '');
    const num = Number.parseInt(cleanedStr, 10);
    if (Number.isNaN(num)) {
      e.target.value = cleanedStr;
      return;
    }
    const clampedNum = Math.max(minValue, Math.min(num, maxValue));
    e.target.value = String(clampedNum);
  };
  const handleDecrease = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setInternalValue(prevValue => Math.max(Number(prevValue) - 1, minValue));
  };
  const handleIncrease = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setInternalValue(prevValue => Math.min(Number(prevValue) + 1, maxValue));
  };
  const handleInternalValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const num = Number.parseInt(e.target.value, 10);
    setInternalValue(isNaN(num) ? '' : num);
  };
  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    onBlur?.(e);
    if (internalValue === '') {
      setInternalValue(initialValue);
    }
  };
  useEffect(() => {
    if (internalValue === '' || isNaN(internalValue)) {
      return;
    }
    onValueChange(internalValue);
  }, [internalValue, onValueChange]);

  // Dynamic input width based on the number of characters
  const inputWidth = `${internalValue.toString().length + 0.5}ch`;
  return <ButtonGroup data-cy="NumberInput" aria-label="Quantity adjustment controls" sx={{
    borderRadius: 0,
    height: 'fit-content',
    width: 'fit-content'
  }} data-sentry-element="ButtonGroup" data-sentry-component="NumberInputInternal" data-sentry-source-file="NumberInput.tsx">
      <IconButton data-cy="NumberInputDecrease" onClick={handleDecrease} aria-label="Decrease" sx={{
      ...baseButtonSx,
      ...buttonSx
    }} data-sentry-element="IconButton" data-sentry-source-file="NumberInput.tsx">
        <RemoveIcon sx={{
        color: 'text.primary',
        fontSize: 14
      }} data-sentry-element="RemoveIcon" data-sentry-source-file="NumberInput.tsx" />
      </IconButton>
      <StyledInput aria-label="Quantity amount" readOnly={isInputDisabled} onInput={cleanInput} name="qty" type="number" value={internalValue} onChange={handleInternalValueChange} onBlur={handleBlur} min={minValue} max={maxValue} step={1} pattern="[0-9]{10}" maxLength={4} data-cy="NumberInputValue" sx={{
      width: inputWidth
    }} ref={ref} data-sentry-element="StyledInput" data-sentry-source-file="NumberInput.tsx" />
      <IconButton data-cy="NumberInputIncrease" onClick={handleIncrease} aria-label="Increase" sx={{
      ...baseButtonSx,
      ...buttonSx
    }} data-sentry-element="IconButton" data-sentry-source-file="NumberInput.tsx">
        <AddIcon sx={{
        color: 'text.primary',
        fontSize: 14
      }} data-sentry-element="AddIcon" data-sentry-source-file="NumberInput.tsx" />
      </IconButton>
    </ButtonGroup>;
};
export const NumberInput = forwardRef(NumberInputInternal);