'use client';

import { useState } from 'react';
import { useDebounce } from 'react-use';
import { Typography } from '@mui/material';
import { LoadingButton } from '@qb/frontend/components/LoadingButton';
import { useIsMobile } from '@qb/frontend/hooks/mediaQueries';
import { PartDisplayDataResponse } from '@/shared/types/controllers/sps/SPSPartControllerTypes';
const ERROR_MESSAGE_QTY_ABOVE_STOCK = 'The quantity you’ve selected exceeds the stock availability. We will process your order, deliver available items and inform you about the lead time.';
export const CallToActionAddToCart = ({
  qty,
  onHandQty,
  part,
  price: _price
}: Pick<PartDisplayDataResponse, 'part'> & {
  qty: number;
  price: number;
  onHandQty: number;
}) => {
  const [errorMessage, setErrorMessage] = useState('');
  const isMobile = useIsMobile();
  useDebounce(() => setErrorMessage(qty > onHandQty ? ERROR_MESSAGE_QTY_ABOVE_STOCK : ''), 200, [qty]);
  const isLoading = false;
  return <>
      {!!errorMessage && <Typography variant="bodySmallError" mb={[1, 1, 3]} sx={{
      gridArea: '1 / 1 / 2 / 3'
    }}>
          {errorMessage}
        </Typography>}

      <LoadingButton fullWidth variant="contained" color="secondary" sx={{
      borderRadius: 0
    }} onClick={() => {
      window?.salesPilotPostMessages?.addToCart({
        qty,
        partID: part.id
      });
    }} loading={isLoading} data-sentry-element="LoadingButton" data-sentry-source-file="CallToActionAddToCart.tsx">
        Add to cart
      </LoadingButton>
      {!isMobile && <LoadingButton fullWidth variant="contained" color="primary" sx={{
      mt: 1
    }} onClick={() => {
      window?.salesPilotPostMessages?.buyNow({
        qty,
        partID: part.id
      });
    }} loading={isLoading}>
          Buy now
        </LoadingButton>}
    </>;
};