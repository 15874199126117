'use client';

import { getImageProps } from 'next/image';
import { Avatar, Badge, keyframes } from '@mui/material';
import { stringToColor } from '@qb/frontend/utils/stringToColor';
type OnlineBadgeProps = {
  alt: string;
  src: string;
  isOnline: boolean;
  outerSize?: number | number[];
  badgeSize?: number | number[];
};
export const OnlineBadge = ({
  alt,
  src,
  isOnline,
  outerSize = [60, 88],
  badgeSize = 16
}: OnlineBadgeProps) => {
  const {
    props: imageProps
  } = getImageProps({
    src,
    alt,
    width: 88,
    height: 88
  });
  return <Badge color="success" overlap="circular" badgeContent="" anchorOrigin={{
    vertical: 'bottom',
    horizontal: 'right'
  }} sx={{
    '.MuiBadge-badge': {
      bgcolor: isOnline ? 'success.light' : 'grey.300',
      minWidth: 'unset',
      width: badgeSize,
      height: badgeSize,
      borderRadius: badgeSize,
      '&::before': {
        display: isOnline ? 'block' : 'none',
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        borderRadius: '50%',
        backgroundColor: 'inherit',
        opacity: 0.5,
        animationName: `${pulsate}`,
        animationDuration: '1s',
        animationIterationCount: 'infinite'
      }
    }
  }} data-sentry-element="Badge" data-sentry-component="OnlineBadge" data-sentry-source-file="OnlineBadge.tsx">
      <Avatar alt={alt} src={imageProps.src} sx={{
      fontSize: 36,
      width: outerSize,
      height: outerSize,
      backgroundColor: stringToColor(alt)
    }} data-sentry-element="Avatar" data-sentry-source-file="OnlineBadge.tsx">
        {alt.at(0)}
      </Avatar>
    </Badge>;
};
const pulsate = keyframes`
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.5);
    opacity: 0.5;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
`;