'use client';

import { ReactNode } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MuiTimeline from '@mui/lab/Timeline';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import { Unstable_Grid2 as Grid, Typography, Box, Stack, useTheme, useMediaQuery } from '@mui/material';
import { TimelineParams } from '@/shared/classes/SPSPageComponentParams/TimelineParams';
const cards = [{
  title: '1972',
  content: "In 1972, Powermatic started as a manufacturer’s representative for the electrical components industry. At that time, it was a sole proprietorship. During the middle 1970's the Company transformed itself from a manufacturers’ representative to a wholesale distributer. Through the years, Powermatic aggressively expanded its distribution pipeline."
}, {
  title: '1980',
  content: 'In 1980 Powermatic incorporated in the state of California under the company name: “LBF Enterprises dba Powermatic Associates, Inc.”  The factory automation products were sold primarily to the semiconductor machine builders and other original equipment manufacturers.'
}, {
  title: '1995',
  content: 'In 1995, Powermatic became the largest OEM (Original Equipment Manufacturer) distributor in the San Francisco Bay Area.'
}, {
  title: '2005',
  content: 'By 2005, we expanded our operations to Austin, Texas, to better serve customers in the Midwest United States'
}, {
  title: '2009',
  content: "In 2009 we opened our Fresno, California operation, extending our offerings to various industries such as food processing, packaging, biotechnology, and agriculture in California's Central Valley."
}, {
  title: '2010',
  content: 'The year 2010 saw the launch of our Singapore operation, reaching machine builders and the electrical mechanical industry in Singapore, Malaysia, China, and South Korea.'
}, {
  title: '2018',
  content: 'In 2018, we became 100% Employee Owned through an Employee Stock Ownership Program (ESOP). We strategically relocated our headquarters to Livermore, California, enhancing our value-added manufacturing capabilities for customized flexible bus bar configurations, electrical panel builds, and personalized automation solutions – a significant growth segment for us.'
}, {
  title: '2022',
  content: 'In 2022, to meet growing customer demand in the Midwest United States, we tripled our manufacturing operations in Austin, Texas. We also opened our Anaheim, California operation to better serve Southern California customers.'
}, {
  title: '2023',
  content: 'In 2023, we achieved ISO 9001:2015 certification at our Singapore and Livermore sites, emphasizing our commitment to maintaining high-quality standards.'
}];
const LeftArrow = () => <Box component="span" sx={{
  position: 'absolute',
  top: '50%',
  right: '-17.5px',
  marginTop: '-17.5px',
  width: '0',
  height: '0',
  borderStyle: 'solid',
  borderWidth: '17.5px 0 17.5px 17.5px',
  borderColor: 'transparent',
  borderLeftColor: 'primary.4p'
}} data-sentry-element="Box" data-sentry-component="LeftArrow" data-sentry-source-file="Timeline.tsx" />;
const RightArrow = () => <Box component="span" sx={{
  position: 'absolute',
  top: '50%',
  left: '-17.5px',
  marginTop: '-17.5px',
  width: '0',
  height: '0',
  borderStyle: 'solid',
  borderWidth: '17.5px 17.5px 17.5px 0',
  borderColor: 'transparent',
  borderRightColor: 'primary.4p'
}} data-sentry-element="Box" data-sentry-component="RightArrow" data-sentry-source-file="Timeline.tsx" />;
const Separator = ({
  index
}: {
  index: number;
}) => <TimelineSeparator data-sentry-element="TimelineSeparator" data-sentry-component="Separator" data-sentry-source-file="Timeline.tsx">
    <TimelineConnector sx={{
    visibility: index === 0 ? 'hidden' : 'visible',
    bgcolor: 'primary.main',
    // in order to align the `TimelineDot` with the Card's content, this value must match the card's title height
    pt: ['7px', '54.8px']
  }} data-sentry-element="TimelineConnector" data-sentry-source-file="Timeline.tsx" />
    <TimelineDot variant="filled" color="primary" sx={theme => ({
    padding: [0.5, 0.5, 0.75],
    boxShadow: `0 0 4px 12px ${theme.palette.primary['4p']}`
  })} data-sentry-element="TimelineDot" data-sentry-source-file="Timeline.tsx" />
    <TimelineConnector sx={{
    visibility: index === cards.length - 1 ? 'hidden' : 'visible',
    bgcolor: 'primary.main'
  }} data-sentry-element="TimelineConnector" data-sentry-source-file="Timeline.tsx" />
  </TimelineSeparator>;
const TimelineCard = ({
  title,
  content,
  isOdd
}: {
  title: ReactNode;
  content: ReactNode;
  isOdd: boolean;
}) => <>
    <Typography variant="h3" align="center" color="primary.main" mb={1} data-sentry-element="Typography" data-sentry-source-file="Timeline.tsx">
      {title}
    </Typography>
    <Typography align="left" variant="paragraph" color="text.primary" sx={{
    p: 2,
    bgcolor: 'primary.4p',
    position: 'relative',
    mb: [6, 0]
  }} data-sentry-element="Typography" data-sentry-source-file="Timeline.tsx">
      {isOdd ? <LeftArrow /> : <RightArrow />}
      {content}
    </Typography>
  </>;
type TimelineProps = {
  params: TimelineParams;
};
export const Timeline = ({
  params
}: TimelineProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  return <Grid container mb={[10, 10, 15]} mt={[10, 10, 15]} data-sentry-element="Grid" data-sentry-component="Timeline" data-sentry-source-file="Timeline.tsx">
      <Grid xs={12} lg={8} lgOffset={2} data-sentry-element="Grid" data-sentry-source-file="Timeline.tsx">
        <Stack alignItems="center" mb={6} data-sentry-element="Stack" data-sentry-source-file="Timeline.tsx">
          <Typography variant="h3" mb={3} align="center" color="primary.main" fontSize={[20, 36]} data-sentry-element="Typography" data-sentry-source-file="Timeline.tsx">
            {params.title}
          </Typography>
          <Box sx={{
          display: 'flex',
          maxWidth: 377,
          width: '100%'
        }} data-sentry-element="Box" data-sentry-source-file="Timeline.tsx">
            <Box sx={{
            my: 2.25,
            bgcolor: 'primary.main',
            height: 2,
            flexGrow: 1
          }} data-sentry-element="Box" data-sentry-source-file="Timeline.tsx" />
            <TimelineDot variant="filled" color="primary" sx={theme => ({
            m: 1.5,
            padding: 0,
            height: 16,
            width: 16,
            boxShadow: `0 0 4px 12px ${theme.palette.primary['4p']}`
          })} data-sentry-element="TimelineDot" data-sentry-source-file="Timeline.tsx">
              <ExpandMoreIcon sx={{
              fontSize: 12
            }} htmlColor="#fff" data-sentry-element="ExpandMoreIcon" data-sentry-source-file="Timeline.tsx" />
            </TimelineDot>
            <Box sx={{
            my: 2.25,
            bgcolor: 'primary.main',
            height: 2,
            flexGrow: 1
          }} data-sentry-element="Box" data-sentry-source-file="Timeline.tsx" />
          </Box>
        </Stack>

        <MuiTimeline position={isMobile ? 'right' : 'alternate-reverse'} sx={{
        px: [0, 0, 0.75]
      }} data-sentry-element="MuiTimeline" data-sentry-source-file="Timeline.tsx">
          {cards.map((card, index) => {
          const isOdd = isMobile ? false : index % 2 === 0;
          const cardPadding = {
            pr: isOdd ? [0, 4, 9] : 0,
            pl: isOdd ? 0 : [5, 4, 9]
          };
          return <TimelineItem key={index} sx={{
            '&:before': {
              ...cardPadding,
              display: ['none', 'block']
            }
          }}>
                <Separator index={index} />
                <TimelineContent sx={cardPadding}>
                  <TimelineCard isOdd={isOdd} title={card.title} content={card.content} />
                </TimelineContent>
              </TimelineItem>;
        })}
        </MuiTimeline>
      </Grid>
    </Grid>;
};