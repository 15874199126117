'use client';

import { Typography } from '@mui/material';
import { LoadingButton } from '@qb/frontend/components/LoadingButton';
import { useIsMobile } from '@qb/frontend/hooks/mediaQueries';
import { PartDisplayDataResponse } from '@/shared/types/controllers/sps/SPSPartControllerTypes';
export const CallToActionRequestQuote = ({
  qty,
  part
}: Pick<PartDisplayDataResponse, 'part'> & {
  qty: number;
}) => {
  const isMobile = useIsMobile();
  return <>
      <LoadingButton fullWidth variant="contained" color="secondary" onClick={() => {
      window?.salesPilotPostMessages?.createQuote({
        qty,
        partID: part.id
      });
    }} loading={false} data-sentry-element="LoadingButton" data-sentry-source-file="CallToActionRequestQuote.tsx">
        Request Quote
      </LoadingButton>
      {!isMobile && <Typography mt={2} variant="bodyLarge" align="center">
          Average response time:{' '}
          <Typography variant="bodyLargePrimary" color="success.main" component="span">
            11 minutes
          </Typography>
        </Typography>}
    </>;
};